<app-login-page-wrapper *ngIf="showLoginForm && currentState == loginStates.LOGIN">
	<form
		  [formGroup]="loginForm"
		  (ngSubmit)="submitLogin()"
		  slot="form"
		  novalidate>
		<h5>Entre em sua conta</h5>
		<div class="mb-2" *ngIf="enableSignUp"><span>ou </span><a [routerLink]="['/signup']" class="text-primary" style="cursor: pointer;">crie uma conta grátis</a></div>
		<div class="form-group pb-0">
			<label for="username">E-mail</label>
			<input type="text"
					id="username"
					formControlName="username"
					class="form-control"
					placeholder="Digite seu e-mail"
					[ngClass]="{ 'is-invalid': submittedLogin && loginFormCtrl.username.errors }"/>
			<div class="invalid-feedback" *ngIf="submittedLogin && loginFormCtrl.username.errors">
				<div *ngIf="loginFormCtrl.username.errors.required">E-mail é obrigatório</div>
			</div>
		</div>
		<div class="form-group">
			<label for="password">Senha</label>
			<div class="input-group">
				<input [type]="showPassword ? 'text' : 'password'"
				       id="password"
				       formControlName="password"
				       class="form-control"
				       (keyup)="changeShowPasswordButtonPosition()"
				       (blur)="changeShowPasswordButtonPosition()"
				       placeholder="Digite sua senha"/>
				<div [class]="'input-group-append ' + passwordButtonPositionClass">
					<i (click)="showPassword = !showPassword" class="fas" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
				</div>
			</div>
		</div>

		<div class="" *ngIf="recaptchaEnabled">
			<re-captcha siteKey="{{ recaptchaId }}" (resolved)="validateRecaptcha($event)" formControlName="recaptchaReactive"></re-captcha>
		</div>

		<div class="clickable-link" routerLink="/forgotPassword">
			Esqueceu sua senha?
		</div>

		<button class="btn btn-primary" [disabled]="isSubmittingForm || loginForm.invalid">
			<span class="spinner-border spinner-border-sm mr-1" *ngIf="isSubmittingForm"></span>
			Entrar
		</button>

		<button type="button" style="margin-top: 14px;" class="btn btn-outline-primary" (click)="loginWithAzure()" *ngIf="enableAzureSignUp">
			<img src="https://id-frontend.prod-east.frontend.public.atl-paas.net/assets/microsoft-logo.c73d8dca.svg" alt="">
			Microsoft
		</button>
	</form>
</app-login-page-wrapper>

<app-login-without-email *ngIf="currentState == loginStates.CHANGE_PASSWORD" (passwordChangeCompleted)="getCurrentSession()"></app-login-without-email>
